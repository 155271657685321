import gsap from 'gsap'

/**
 * Creates a GSAP timeline that acknowledges prefer-reduced-motion
 */
export const smartTimeline = (): GSAPTimeline => {
	if (typeof window !== `undefined`) {
		if (
			window.matchMedia('(prefers-reduced-motion: reduce)')
				.matches
		) {
			// timeline.paused(true)
			return gsap.timeline({ paused: true })
		} else {
			return gsap.timeline({ paused: false })
		}
	}
	return gsap.timeline({ paused: false })
}
