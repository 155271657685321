import React, { Component } from 'react'
import SEO from 'Components/seo'
import Gamepad from 'Components/gamepad/Gamepad'
import variables from 'SCSS/config.scss'
import 'SCSS/home.scss'
import { AutoTransLink } from 'Animation/transitions'
import { smartTimeline } from '../utils/animations/control'
import Reel from 'Assets/reel/Reel'


const pages = [
	{name: 'About', button: 'A', className: 'mobile-pad-btn-a', path: '/about'},
	{name: 'Blog', button: 'B', className: 'mobile-pad-btn-b', path: '/blog'},
	{name: 'Experience', button: 'X', className: 'mobile-pad-btn-x', path: '/experience'},
	{name: 'Contact', button: 'Y', className: 'mobile-pad-btn-y', path: '/contact'},
]

class HomePage extends Component {
	isHovering = false
	timeline = smartTimeline()

	constructor(props) {
		super(props)

		this.state = {
			hovering: null,
			inProp: true,
		}

		this.handleButtonEnter = this.handleButtonEnter.bind(this)
		this.handleButtonExit = this.handleButtonExit.bind(this)
	}

	componentDidMount() {
		this.timeline.repeat(-1)
		.repeatDelay(0.75)
		.to('#selectedButton', {opacity: 1, duration: 0.75})
		.to('#selectedButton', {opacity: 0, duration: 0.75})
	}

	handleButtonEnter(buttonChar) {
		let btn = document.getElementById('selectedButton')
		this.isHovering = true
		this.timeline.restart().pause()
		
		switch (buttonChar.toLowerCase()) {
			case 'a':
				btn.style.color = variables.aFill
				break
			case 'b':
				btn.style.color = variables.bFill
				break
			case 'x':
				btn.style.color = variables.xFill
				break
			case 'y':
				btn.style.color = variables.yFill
				break

			default:
				break
		}
		let match = pages.find(item => item.button.toLowerCase() === buttonChar.toLowerCase())
		btn.innerHTML = match ? match.name : btn.innerHTML
	}

	handleButtonExit() {
		let btn = document.getElementById('selectedButton')
		this.isHovering = false
		
		this.timer = setTimeout(() => {
			if(!this.isHovering) {
			btn.innerHTML = 'Press Any Button'
			// btn.style.opacity = "initial"
			btn.style.color = null
			this.timeline.resume()
			}
		}, 250)
	}

	render() {
		// const { key, pathname } = this.props.location
		return (
			// <TransitionGroup>
			// 	<Transition
			// 		appear
			// 		mountOnEnter
			// 		unmountOnExit
			// 		timeout={1500}
			// 		onEnter={(node, isAppearing) =>
			// 			transitions.enterHome(
			// 				{ length: 1.5 },
			// 				node
			// 			)
			// 		}
			// 		onExit={(node, isAppearing) => {
			// 			transitions.exitHome(
			// 				{ length: 1.5 },
			// 				node
			// 			)
			// 		}}
			// 	>
			// 		{state => {
			// 			return (
			<div className='home-container'>
				<SEO title="Home" url='/' />
				<div className="home">
					<div className='home-spacer' />
					<div className="titles">
						<h1
							className="title"
							style={{
								flex: '1',
								textAlign:
									'center',
							}}
						>
							Gino Valente
						</h1>
						<h2
							id="selectedButton"
							className={
								// this.state
								// 	.hovering ===
								// null
								// 	? 'blinky subtitle'
								// 	: 'subtitle'
								'subtitle'
							}
							style={{
								flex: '1',
								textAlign:
									'center',
								userSelect:
									'none',
								transition:
									'opacity 1.0s ease, color 0.75s ease',
							}}
						>
							{this.state.hovering ===
							null
								? 'Press Any Button'
								: this.state
										.hovering}
						</h2>
					</div>
					<div className="gamepad-home">
						<Gamepad
							links
							animations="all"
							from="/"
							aTo="/about"
							bTo="/blog"
							xTo="/experience"
							yTo="/contact"
							strokeWidth={1}
							handleButtonEnter={
								this
									.handleButtonEnter
							}
							handleButtonExit={
								this
									.handleButtonExit
							}
							style={{
								height: '60vh',
								width: 'auto',
								position:
									'relative',
								flex: '1',
								display: 'flex',
								justifyContent:
									'center',
							}}
						/>
					</div>
						<div className="mobile-pad">
							<nav>
								<ul className='mobile-pad-list nav-list'>
								{
									pages.map((page, index) => {
										return (
											<li key={index}>
												<AutoTransLink wait to={page.path} from={'/'} className={`mobile-pad-btn ${page.className}`} >
													<div>{page.button}</div>
													<h3>{page.name}</h3>
												</AutoTransLink>
											</li>
										)
									})
								}
								</ul>
							</nav>
						</div>
					{/* <Reel className='home-bg' /> */}
				</div>
			</div>
			// 			)
			// 		}}
			// 	</Transition>
			// </TransitionGroup>
		)
	}
}

export default HomePage
