import React, { HTMLAttributes, useState, useEffect } from 'react'
import styles from './Reel.module.scss'
import vhs4 from './clips/vhs-04.gif'
import vidSun1 from './clips/reel-sun.mp4'
import vidSun2 from './clips/reel-sun-2.mp4'
import imgCam from './clips/reel-cam.mp4'
import imgGen from './clips/reel-gen.mp4'
import imgPT from './clips/reel-pt.mp4'
import imgFence from './clips/reel-fence.jpg'
import imgBooth from './clips/reel-booth.jpg'
import imgDesk from './clips/reel-desk.jpg'
import { fileType, isImage } from '../../utils/tools/filename'

const vhsList = []

const imageList = [vidSun1, vidSun2, imgCam, imgGen, imgPT, imgFence, imgBooth, imgDesk]

interface IReel extends HTMLAttributes<HTMLDivElement> {
	blur?: number
	opacity?: number
	brightness?: number;
	maxSeconds?: number
	minSeconds?: number
}

export default function Reel({
	blur = 2,
	opacity = 0.25,
	brightness = 0.5,
	maxSeconds = 7,
	minSeconds = 3,
	className,
	...etc
}: IReel) {
	const [image, setImage] = useState()
	const [timer, setTimer] = useState(-1)

	useEffect(() => {
		function start() {
			clearTimeout(timer)
			let time = Math.floor(Math.random() * maxSeconds * 1000)
			time = Math.max(time, minSeconds * 1000)
			setTimer(setTimeout(selectImage, time))
		}

		function selectImage() {
			let index = Math.floor(Math.random() * imageList.length)
			imageList.length > 0 && setImage(imageList[index])
			start()
		}

		if (timer === -1) {
			start()
			selectImage()
		}

		return () => {
			clearTimeout(timer)
		}
	})

	return (
		<div
			className={`${styles.reel} ${className}`}
			style={{
				filter: `blur(${blur}px) brightness(${brightness})`,
			}}
		>
			{
				image && isImage(image!) ? (
					<ImageReel src={image!} vhs={vhs4} opacity={opacity} />
				) : (
					<VideoReel src={image!} vhs={vhs4} opacity={opacity} />
				)
			}
		</div>
	)
}

interface IReelComponent extends HTMLAttributes<HTMLElement> {
	src: string;
	vhs: string;
	opacity?: number;
}
const VideoReel = ({src, vhs, opacity}: IReelComponent) => (
	<div className={styles.reelVideoWrapper} style={{
		backgroundImage: `url(${vhs})`,
		opacity: opacity
	}}>
		<video className={styles.reelVideo} autoPlay={true} controls={false} muted={true} loop={true} preload={'auto'}>
			<source src={src} />
		</video>
	</div>
)
const ImageReel = ({src, vhs, opacity}: IReelComponent) => (
	<div
		className={styles.reelClip}
		style={{
			backgroundImage: `url(${src}), url(${vhs})`,
			opacity: opacity,
		}}
	/>
)